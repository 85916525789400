import React from 'react';
import { connect } from 'react-redux';
import { Input, Card, Button } from 'antd';
import styled from 'styled-components';
import { NativeButtonProps } from 'antd/lib/button/button';

import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { selectBenefitContract } from 'features/benefit/actions';
import {
    autocompleteRequest,
    clearInitialValueData,
    getInitialValueRequest,
} from 'features/pagination/actions/paginationActions';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { notArchivedFilterParam } from 'config/config';
import { ContractPriorities } from 'features/contract/models/contractModels';
import { BenefitPriority } from 'features/benefit/models/benefitModels';
import { Antd3Icon, Antd3FormProps } from 'common/components/deprecated/antd3';

const EditableInputComponent = styled.div`
    display: flex;
    justify-content: space-between;
`;

const InputWrapper = styled.div`
    width: 70%;
`;

const StyledButton = styled((props: NativeButtonProps) => <Button {...props} />)`
    margin-bottom: 1.5rem;
`;

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteSelectionProps {
    selectNewPriority: (priorityData: ContractPriorities) => void;
    deletePriority: (priorityID: number) => void;
    changePriorityValue: (priorityID: number, value: number) => void;
    selectedPriorities: ContractPriorities[] | BenefitPriority[] | undefined;
    autocompleteField: AutocompleteField;
    id: string;
    placeholder: string;
    notFoundContent: string;
    tooltip?: string;
}

class PrioritiesFields extends React.Component<
    AutocompleteSelectionProps & AutocompleteDispatchProps & Antd3FormProps
> {
    private onDeselectSelection = (value: number) => {
        this.props.deletePriority(value);
    };

    public render(): JSX.Element {
        const {
            form,
            selectedPriorities,
            changePriorityValue,
            selectNewPriority,
            autocompleteField,
            id,
            placeholder,
            notFoundContent,
            tooltip,
        } = this.props;

        const prioritiesValues =
            selectedPriorities && selectedPriorities.length ? (
                <>
                    {selectedPriorities.map(({ priority, priorityName, id, value }) => (
                        <EditableInputComponent key={id}>
                            <InputWrapper>
                                {priority?.name || priorityName}
                                <Input
                                    id="value"
                                    placeholder={`${priority?.name || priorityName} value`}
                                    type="number"
                                    onChange={(e) =>
                                        changePriorityValue(id, Number(e.target.value))
                                    }
                                    value={value}
                                />
                            </InputWrapper>

                            <StyledButton
                                size="small"
                                type="danger"
                                ghost
                                onClick={() => this.onDeselectSelection(id)}
                            >
                                <Antd3Icon type="delete" />
                                Remove
                            </StyledButton>
                        </EditableInputComponent>
                    ))}
                </>
            ) : null;

        return (
            <Card>
                <AutocompleteSelect
                    form={form}
                    autocompleteField={autocompleteField}
                    id={id}
                    placeholder={placeholder}
                    notFoundContent={notFoundContent}
                    searchParams={notArchivedFilterParam}
                    onDeselectSelection={this.onDeselectSelection}
                    getAutocompleteData={selectNewPriority}
                    required={false}
                    tooltip={tooltip}
                />
                {prioritiesValues}
            </Card>
        );
    }
}

export const PrioritiesForm = connect(null, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
})(PrioritiesFields);
